<template>
  <div class="dashboard__container--body" v-if="(userProfile  && userProfile.id)">
    <div class="dashboard__container--body--col">
     <ProfileTagline :userProfile="userProfile" :showtitle="false" class="mb-5" />
    </div>
  </div>
</template>


<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import ProfileTagline from '@/components/Profile/ProfileTagline.vue'

export default {
  name: 'accountTagline',
  computed: {
    ...mapState(['userProfile', 'currentUser']),
  },
  components: {
    Loader,
    ProfileTagline
  },
}
</script>