<template>

    <div class="mb-3 whiteBack">
      <h4 v-if="showtitle"class="mb-5">Add a Tagline to your Profile</h4>
      <label class="mb-3">Tagline (96 characters)</label>
      <textarea v-if="userProfile" name="details" v-model.trim="userProfile.tagline" id="details" cols="30" rows="2" @blur="updateProfile()" :maxlength="max"></textarea>
      <button class="btn btn__primary mt-5" v-if="showButton" @click="updateTagline()">Submit</button>
      <button class="btn btn__dark mt-5" v-if="skipButton && !showButton" @click="skip()">
        Skip This Step
      </button>
    </div>
		<!-- <div class="mb-3">
      <vue-editor id="bio" v-model.trim="userProfile.bio" placeholder="Education, experience, job history, interests, etc..." @change="updateProfile()" :editor-toolbar="customToolbar"></vue-editor>
    </div>
    <div class="mb-3">
      <h3>Upload your resumé</h3>
      <a :href="userProfile.picture" v-if="userProfile.picture" target="_blank" class="caption">View Your Resumé <i class="fas fa-external-link ml-1 mt-2"></i></a>


      <input type="file" ref="file" accept="image/*,.pdf" @change="previewImage" class="mt-3"> -->

      <!-- <div class="mt-3">
        <button class="btn btn__primary" @click="$refs.file.click()">Choose File <i class="far fa-folder-open ml-3"></i></button>
      </div> -->

<!-- 
      <progress :value="uploadValue" max="100" v-if="showBar"></progress>
      <div>
        <button v-if="imageData != null" class="btn btn__primary mt-3" @click="onUpload">Upload</button>
      </div>
    </div> -->

</template>

<script>
export default {
  props: ['userProfile', 'showtitle', 'skipButton'],
   data: () => ({
    max: 96,
    showButton: false,
    performingRequest: false,
    tagline: ''
  }),
  methods: {
    skip() {
      let userProfile = this.userProfile
      this.performingRequest = true
      userProfile.tagline = '   '
      this.$store.dispatch('updateUser', userProfile)
      setTimeout(() => {
        this.performingRequest = false
        this.showButton = false
      }, 1000)
    },
    showIt() {
      this.showButton = true
    },
    // updateProfile(){
    // 	let userProfile = this.userProfile
    //   this.performingRequest = true
    // 	this.$store.dispatch('updateUser', userProfile)
    //   setTimeout(() => {
    //     this.performingRequest = false
    //     this.showButton = false
    //   }, 1000)
    // },
    updateTagline(){
      let userProfile = this.userProfile
      this.performingRequest = true
      this.$store.dispatch('updateUser', userProfile)
      setTimeout(() => {
        this.performingRequest = false
        this.showButton = false
      }, 1000)
    },
  }
}
</script>